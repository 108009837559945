import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {

  return (
    <div className="bg-gray-50 flex flex-col min-h-screen">
        <div className="flex min-h-screen items-center justify-center">
            <div>
                <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                    <div className="relative flex w-full max-w-[48rem] flex-row rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                        <div className="p-6">
                            <h4 className="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal">
                            Privacy Policy
                            </h4>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Effective Date: 1/11/2025
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Kowaaluh LLC ("we", "our", "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy describes the types of personal information we collect about our customers, how we use the information, with whom we share it, and the choices available to our customers regarding our use of the information. We also describe the measures we take to protect the security of the information and how our customers can contact us about our privacy practices.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                1. Information We Collect
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We collect personal identification information when you voluntarily provide it to us through our survey or our newsletter signup form. Specifically, we may collect the following information:
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Contact information (such as name, email, and phone number)
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Age and date of birth
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                When you visit this site or mobile applications, we may also collect certain information by automated means, using technologies such as cookies, web server logs and web beacons.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                2. How We Use Your Information
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We may use your information for the following purposes:
                            </p>
                            <p className="block font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                To communicate with you, respond to inquiries, or provide customer support.
                            </p>
                            <p className="block font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Send you promotional materials or other communications.
                            </p>
                            <p className="block font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Operate and communicate with you about our social networking or mobile applications.
                            </p>
                            <p className="block font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Comply with applicable legal requirements, relevant industry standards and our policies. We also may use the information in other ways for which we provide specific notice at the time of collection.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                3. How We Protect Your Information
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We maintain appropriate administrative, technical and physical safeguards designed to protect the personal information you provide against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                4. Sharing Your Information
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following cases:
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                With Service Providers: We may share your email address with third-party service providers who assist us in running our Site or providing services to you (e.g., email delivery services).
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Legal Requirements: We may disclose your information if required by law, or if we believe it is necessary to protect our rights or comply with legal processes.
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We also reserve the right to transfer personal information we have about you in the event we sell or transfer all or a portion of our business or assets. Should such a sale or transfer occur, we will use reasonable efforts to direct the transferee to use personal information you have provided to us in a manner that is consistent with our Privacy Policy. Following such a sale or transfer, you may contact the entity to which we transferred your personal information with any inquiries concerning the processing of that information.                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                5. Third-Party Links
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Our Site may contain links to third-party websites that are not operated or controlled by us. Please be aware that we are not responsible for the privacy practices of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit through links on our Site.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                6. Your Data Protection Rights
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We offer you certain choices in connection with the personal information we collect from you, such as how we use the information and how we communicate with you. To update your preferences, ask us to remove your information from our mailing lists or submit a request, please contact us as specified below.
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                The right to access the personal information we hold about you.
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Email Opt-Out You can at any time tell us not to send you marketing communications by email by clicking on the unsubscribe link within the marketing emails you receive from us or by
                                <Link to="/contactus" className="font-semibold mx-1">
                                  contacting us.
                                </Link>
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Reviewing, Updating and Modifying Personal Information Subject to applicable law, you may have the right to request access to and receive details about the personal information we maintain about you, update and correct inaccuracies in your personal data, and have the information blocked or deleted, as appropriate. The right to access personal information may be limited in some circumstances by local law requirements. You may request to review, change or delete your personal information by sending an email using our contact page.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                7. Children’s Privacy
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Our services are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children. If we discover that we have collected personal data from a child, we will take steps to delete that information as soon as possible.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                8. Changes to This Privacy Policy
                            </p>
                             <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                 We may update this privacy policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When we make updates, we will revise the "Effective Date" at the top of this page. We encourage you to review this policy periodically to stay informed about how we are protecting your personal information.
                             </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                9. Contact Us
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                If you have any questions or concerns about this privacy policy or how we handle your personal information, please
                                <Link to="/contactus" className="font-semibold mx-1">
                                  contact us.
                                </Link>
                            </p>
                            <div className="flex justify-end ">
                                <Link to="/">
                                    <button
                                        type="button" className="bg-main rounded uppercase text-sm text-base font-semibold px-3 py-1.5 text-white sm:rounded-l-md sm:rounded-r-md hover:bg-hover">
                                        Home
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
}

export default PrivacyPolicy;