import React from 'react';
import logo from '../assets/logo.png';
import tiktok from '../assets/tiktok.png';
import instagram from '../assets/instagram.png';
import blue_sky_social from '../assets/blue_sky_social.png';
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
      <footer className="bg-white dark:bg-gray-900">
          <div className="container px-6 py-8 mx-auto">
              <div className="flex flex-col items-center text-center">
                  <p className="uppercase max-w-md mx-auto mt-2 text-black font-bold">Join Our Community</p>
                  <p className="mx-auto mt-2 text-black font-light">@pairedplateapp</p>
                  <div className="mt-4 flex">
                      <a href="https://bsky.app/profile/pairedplateapp.bsky.social" >
                          <img className="px-2 w-auto h-5" src={blue_sky_social} alt="Blue Sky Social Logo"/>
                      </a>
                      <a href="https://www.instagram.com/pairedplateapp/">
                          <img className="px-2 w-auto h-5" src={instagram} alt="Instagram Logo"/>
                      </a>
                      <a href="https://www.tiktok.com/@pairedplateapp">
                          <img className="px-2 w-auto h-5" src={tiktok} alt="TikTok Logo"/>
                      </a>
                  </div>
              </div>
              <hr className="my-10 border-gray-200 dark:border-gray-700" />
              <div className="flex flex-col items-center sm:flex-row sm:justify-between">
                  <p className="text-sm text-gray-500">© Copyright 2025 pairedplate.com. All Rights Reserved.</p>
                  <div className="flex mt-3 -mx-2 sm:mt-0">
                      <Link to="/privacy" className="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"> Privacy Policy </Link>
                      <Link to="/termsofservice" className="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"> Terms of Service </Link>
                      <Link to="/contactus" className="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"> Contact Us </Link>
                  </div>
              </div>
          </div>
      </footer>
  );
}

export default Footer;