import React from 'react';
import background from '../assets/background.png';
import phone from '../assets/phone.png';

const Main = () => {
  return (
    <main className="bg-white flex flex-col min-h-screen"
        style={{ backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'}}>
        <div class="py-10">
            <div class="container mx-auto flex flex-col md:flex-row items-center my-12 md:my-24">
                <div class="flex flex-col w-full lg:w-1/2 justify-center items-start p-8">
                    <h1 class="uppercase text-3xl md:text-5xl text-black font-extrabold mb-4">Pairing you with plant-based food on the go</h1>
                    <p class="text-sm text-black mb-4">Please fill out our survey, and in return, you'll be among the first to be notified when we launch.</p>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdoxNJMsuuSwPz3bdmW0kEKQdoM-IbEzwJN7x66nTA89WTAHQ/viewform?usp=header"
                        class="uppercase py-4 text-white text-base font-bold px-10 bg-main hover:bg-hover">
                        Fill Out Survey</a>
                </div>
                <div class="p-8 mt-12 mb-6 md:mb-0 md:mt-0 ml-0 md:ml-12 lg:w-2/3 justify-center">
                    <div class="h-48 flex flex-wrap content-center">
                        <img class="inline-block mt-28 lg:block"
                            alt="A mobile app with search bar and lists of restaurants."
                            src={phone}/>
                    </div>
                </div>
            </div>
        </div>
    </main>
  );
}

export default Main;