import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const NavBar = () => {

  return (
    <div className="bg-white sticky top-0 z-30 flex justify-center shadow">
        <header className="p-4 relative overflow-hidden">
          <Link to="/">
            <img
              src={logo}
              alt="Paired Plate logo"
              className="h-8 w-auto object-contain"
            />
          </Link>
        </header>
    </div>
  );
}

export default NavBar;