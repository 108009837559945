import { Navigate, Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsofService from './components/TermsofService';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  return (
      <div>
          <NavBar/>
              <Routes>
                <Route
                    path="/contactus"
                    element={
                        <Contact />
                    }
                />
                <Route
                    path="/"
                    element={
                        <Main />
                    }
                />
                <Route
                    path="*"
                    element={<Navigate to="/" element={<Main />} replace />}
                />
                <Route path="/privacy"
                    element={
                      <PrivacyPolicy />
                    }
                />
                <Route path="/termsofservice"
                    element={
                      <TermsofService />
                    }
                />
            </Routes>
          <Footer/>
      </div>
  );
}

export default App;
