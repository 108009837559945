import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
      <div className="container mx-auto py-8">
        <h1 className="text-2xl mb-6 text-center block font-sans antialiased font-semibold leading-snug tracking-normal">Contact Us</h1>
        <form
          className="w-full max-w-sm mx-auto bg-white p-8 rounded-md shadow-md"
          action="https://formspree.io/f/mdkkbbaw"
          method="POST">
          <div className="mb-4">
            <span className="block text-gray-700 text-sm font-semibold mb-2">Email</span>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
              required
              type="email"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}/>
          </div>
          <div className="mb-4">
            <span className="block text-gray-700 text-sm font-semibold mb-2">Message</span>
            <textarea
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none"
              required
              id="message"
              name="message"
              onChange={(e) => setMessage(e.target.value)}/>
          </div>
          <div className="mb-4 flex gap-x-4 sm:col-span-2">
            <input
                className="form-checkbox h-5 w-5"
                required
                type="checkbox"
                id="checkbox"
                onChange={(e) => setAgreed(e.target.value)}
            />
            <span className="text-sm text-black">
              By selecting this, you agree to our{' '}
              <Link to="/privacy" className="font-semibold text-main">
              privacy&nbsp;policy
              </Link>
              .
            </span>
          </div>
          <button
            className="uppercase w-full bg-main text-white text-sm text-base font-semibold py-2 px-4 rounded-md hover:bg-hover transition duration-300"
            type="submit">Submit
          </button>
        </form>
      </div>
  );
}

export default Contact;